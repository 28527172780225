import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'remixicon/fonts/remixicon.css'
import 'animate.css/animate.min.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import 'swiper/swiper-bundle.min.css'
import '@/assets/css/style.scss'
import '@/assets/css/iconsax.css'
import '@/assets/js/iconsax.js'
import '@/assets/js/custom-cursor.js'
import i18n from '@/locales/index'


const appAOS = new AOS.init({disable: 'phone'});
createApp(App).use(router).use(store).use(appAOS).use(i18n).mount('#app')
