import { defineStore } from 'pinia';

export const useCounterStore = defineStore('counter', {
  state: () => ({
    count: 1,
    isLoading: false,
  }),
  actions: {
    accumulate() {
      this.count++;
    },
    updateLoadingStatue(status) {
      this.isLoading = status;
    },
  },
});