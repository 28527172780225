import { createI18n } from "vue-i18n";
import en from "./en.json";
import zh from './zh.json';
import zhHk from './zh-HK.json';
import ja from './ja.json';
import korea from './korea.json';
import es from './es.json';

const messages = {
  en,
  "zh-CN":zh,
  "zh-HK": zhHk,
  ja,
  korea,
  es
};
const language = "en";

const i18n = createI18n({
  locale: localStorage.getItem('lang') || language,
  fallbackLocale: 'en',
  messages
})
export default i18n;